<template>
  <div class="security">
    <h1>{{ pageComponents.page_title }}</h1>
    <p class="mb-24">{{ pageComponents.page_subtitle }}</p>
    <section class="s-w-30">
      <h2 class="mb-48">{{ pageComponents.selling_points_header }}</h2>
      <div v-for="(sellingPoint, index) in sellingPoints" :key="index">
        <h3 class="mb-24">{{ sellingPoint.title }}</h3>
        <p class="mb-24" v-html="sellingPoint.description"></p>
      </div>
       </section>
          <section>
      <div v-if="highlightedPoint.title" class="highlighted">
        <h3 class="mb-24">{{ highlightedPoint.title }}</h3>
        <p class="mb-24" v-html="highlightedPoint.description"></p>
        <ul class="highlights">
          <li v-for="(point, index) in highlightedPoint.highlights" :key="index" class="highlight">
            <img :src="GREEN_CHECK" class="bullet">
            {{ point.highlight }}
          </li>
        </ul>
      </div>
    </section>
    <section class="discloser-sec">
      <h2 class="mb-24">{{ vulnerabilitiesContent.title }}</h2>
      <p class="mb-24" v-html="vulnerabilitiesContent.description"></p>
      <div v-for="(block, index) in vulnerabilitiesContent.copyable_block" :key="index" class="copy-block">
        <p class="mb-24">{{ block.advice }}</p>
        <button @click="throttledCopyToClipboard(block.content, index)" :class="copyBlockClasses[index]">
          <div class="label">{{ block.label }}</div>
          <div class="advisory">{{ $t('copiado') }}</div>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { throttle } from 'underscore';
import { GREEN_CHECK } from '@/constants/cloudinary';
import {
  butter
} from '@/buttercms';

const COPY_THROTTLE = 1_500;

export default {
  name: 'Security',
  data() {
    return {
      page: {},
      copyBlockClasses: [],
    };
  },
  computed: {
    pageComponents() {
      return this.page.fields || {};
    },
    sellingPoints() {
      return this.pageComponents.selling_points
        ? this.pageComponents.selling_points.selling_point
        : [];
    },
    highlightedPoint() {
      return this.pageComponents.highlighted_selling_point || [];
    },
    cardData() {
      return this.pageComponents.card_component || [];
    },
    vulnerabilitiesContent() {
      return this.pageComponents.copyable_text || { copyable_block: [] };
    },
  },
  created() {
    this.GREEN_CHECK = GREEN_CHECK;
    this.fetchPage();
    this.throttledCopyToClipboard = throttle(
      this.copyToClipboard,
      COPY_THROTTLE,
      { trailing: false }
    );
  },
  methods: {
    fetchPage() {
      const locale = this.$route.params.locale;
      butter.page.retrieve('security', 'security-privacy', { locale: locale })
        .then((response) => {
          this.page = response.data.data;
          this.vulnerabilitiesContent.copyable_block.forEach(() => {
            this.copyBlockClasses.push(['copy']);
          });
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    copyToClipboard(content, index) {
      navigator.clipboard.writeText(content)
        .then(() => {
          this.toggleCopyState(index);
          setTimeout(this.toggleCopyState, COPY_THROTTLE, index);
        });
    },
    toggleCopyState(index) {
      if (!this.copyBlockClasses[index]) {
        return;
      }
      const isCopiedState = this.copyBlockClasses[index].length > 1;
      const updatedClasses = isCopiedState ? ['copy'] : ['copy', 'copied'];
      this.$set(this.copyBlockClasses, index, updatedClasses);
    },
  },
};
</script>

<style lang="scss" scoped="">
::v-deep h1 {
  margin-bottom: 1.5rem;
}

::v-deep h3:not(:first-child) {
  margin-top: 0.5rem;
}

::v-deep .section-card{
  padding: 6.5 0 6.5rem 0;

  .header {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
}

.highlighted {
  margin-top: 3rem;
  padding: 2rem;
  background-color: #ebf4ff;
  border-radius: 1rem;
   @include bp-medium {
    margin-top: 6.5rem;
  }

  .highlights {
    display: flex;
    flex-direction: column;
    list-style-type: none;

    @include bp-mediumsmall {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-auto-flow: column;
    }

    @include bp-medium {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
      grid-auto-flow: row;
    }

    @include bp-mediumlarge {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .highlight {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    letter-spacing: -0.3px;

    .bullet {
      height: 1rem;
      margin-right: 0.5rem;
    }

    &::before {
      content: none;
    }

    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
}

.copy-block {
  margin-bottom: 2rem;
}

.mb-48 {
  margin-bottom: 1.5rem;
   @include bp-medium {
    margin-bottom: 3rem;
  }
}

.mb-24 {
  margin-bottom: 1rem;
  @include bp-medium {
    margin-bottom: 1.5rem;
  }
}
.s-w-30 {
  width: 30rem;
  @include bp-xs {
    width: 100%;
  }
}

.discloser-sec {
  margin-top:6.5rem;
}

.copy {
  min-width: 16rem;
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 1.75rem;
  color: $action-blue;
  background-color: #edf4fe;

  > div {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
  }

  .advisory {
    display: none;
  }

  &.copied {
    color: $white;
    background-color: $action-blue;

    .label {
      visibility: hidden;
      height: 0;
    }

    .advisory {
      display: block;
      width: 100%;
    }
  }
}
</style>
